@import '../base/required';

$cardWidth: 390px;

.content-carousel {

    &--with-content {
        display: grid;
        grid-template-columns: calc(#{$cardWidth} + 34px) 1fr;
        grid-template-areas: "content carousel";

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;

            &.content-carousel__content--above {
                grid-template-areas: "content" "carousel";
            }

            &.content-carousel__content--below {
                grid-template-areas: "carousel" "content";
            }
        }
    }
}

    .content-carousel__content {
        grid-area: content;
        padding: 0 $productCarouselWidth;

        margin-top: 49px; // annoyingly specific

        @include breakpoint(phablet) {
            padding: 0 $site-gutter--mobile;
        }
    }

    @include breakpoint(tablet) {
        .content-carousel__content--above .content-carousel__content {
            margin-top: 0;
            margin-bottom: -25px;
        }

        .content-carousel__content--below .content-carousel__content {
            margin-top: 0;
        }
    }

        .content-carousel__content-title {
            @include apply-map($f-title);
            @include font(30px, 40px);

            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font(24px, 32px);
            }
        }

        .content-carousel__content-body.content-carousel__content-body {
            width: 100%;
            max-width: 300px;

            @include font(16px, 24px);

            @include breakpoint(tablet) {
                @include font(14px, 22px);
                max-width: none;
            }
        }

    .content-carousel__carousel {
        grid-area: carousel;
        min-width: 0;
    }

        .content-carousel__carousel-header {
            position: relative;
            margin: 0 $site-gutter;

            @include breakpoint(phablet) {
                margin: 0 $site-gutter--mobile;
            }

            .content-carousel--with-content & {
                margin: 0 $site-gutter 40px;

                @include breakpoint(phablet) {
                    margin: 0 $site-gutter--mobile 40px;
                }
            }

            .band__header {
                margin-bottom: 22px;
            }
        }

            .content-carousel__carousel-controls {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @include breakpoint(phablet) {
                    display: none;
                }
            }

                .content-carousel__carousel-left,
                .content-carousel__carousel-right {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    background: transparent;

                    &[disabled] {
                        opacity: 0.3;
                        pointer-events: none;
                    }

                    svg {
                        width: 6px;
                        height: 10px;
                    }
                }

        .content-carousel__carousel-container {
            display: flex;
            overflow: auto;
            margin-bottom: 20px;
            gap: 10px;

            padding: 9px $site-gutter 20px;

            @include breakpoint(phablet) {
                margin-bottom: 10px;
                gap: 16px;

                padding-left: $site-gutter--mobile;
                padding-right: $site-gutter--mobile;
            }

            .content-carousel--with-content & {
                padding-left: 0;

                @include breakpoint(tablet) {
                    padding-left: $site-gutter;
                }

                @include breakpoint(phablet) {
                    padding-left: $site-gutter--mobile;
                }
            }
        }

        .content-carousel__carousel-item {
            @include breakpoint(phablet) {
                flex: 0 0 auto;
                width: calc(50vw - calc(#{$productCarouselWidth} * 2));
            }
        }

            .content-carousel__carousel-item-link {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .content-carousel__carousel-item-image {
                width: $cardWidth;
                margin-bottom: 18px;

                @include breakpoint(tablet) {
                    width: 280px;
                }

                @include breakpoint(phablet) {
                    width: auto;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .content-carousel__carousel-item-title {
                @include apply-map($f-primary);
                @include font(24px, 32px);

                text-decoration: none;
                color: $c-title;

                .content-carousel__carousel-item-link:hover & {
                    text-decoration: underline;
                }

                @include breakpoint(phablet) {
                    @include font(18px, 24px);
                }
            }
