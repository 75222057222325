:root {
  --c-border: #000000;
  --c-panel-background: #FFFFFF;
  --c-disabled: #EEEEEE;
  --c-focus: #000000;
  --c-field-disabled: var(--c-disabled);
  --c-field-focus: var(--c-focus);
  --c-input-background: var(--c-panel-background);
  --c-input-border: var(--c-border);
}

.content-carousel--with-content {
  display: grid;
  grid-template-columns: calc(390px + 34px) 1fr;
  grid-template-areas: "content carousel";
}
@media screen and (max-width: 62.4375em) {
  .content-carousel--with-content {
    grid-template-columns: 1fr;
  }
  .content-carousel--with-content.content-carousel__content--above {
    grid-template-areas: "content" "carousel";
  }
  .content-carousel--with-content.content-carousel__content--below {
    grid-template-areas: "carousel" "content";
  }
}

.content-carousel__content {
  grid-area: content;
  padding: 0 24px;
  margin-top: 49px;
}
@media screen and (max-width: 42.5em) {
  .content-carousel__content {
    padding: 0 16px;
  }
}

@media screen and (max-width: 62.4375em) {
  .content-carousel__content--above .content-carousel__content {
    margin-top: 0;
    margin-bottom: -25px;
  }
  .content-carousel__content--below .content-carousel__content {
    margin-top: 0;
  }
}
.content-carousel__content-title {
  font-family: Cardo, serif;
  line-height: 110%;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.3333333333;
  margin-bottom: 20px;
}
@media screen and (max-width: 62.4375em) {
  .content-carousel__content-title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}

.content-carousel__content-body.content-carousel__content-body {
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (max-width: 62.4375em) {
  .content-carousel__content-body.content-carousel__content-body {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714;
    max-width: none;
  }
}

.content-carousel__carousel {
  grid-area: carousel;
  min-width: 0;
}

.content-carousel__carousel-header {
  position: relative;
  margin: 0 24px;
}
@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-header {
    margin: 0 16px;
  }
}
.content-carousel--with-content .content-carousel__carousel-header {
  margin: 0 24px 40px;
}
@media screen and (max-width: 42.5em) {
  .content-carousel--with-content .content-carousel__carousel-header {
    margin: 0 16px 40px;
  }
}
.content-carousel__carousel-header .band__header {
  margin-bottom: 22px;
}

.content-carousel__carousel-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-controls {
    display: none;
  }
}

.content-carousel__carousel-left,
.content-carousel__carousel-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: transparent;
}
.content-carousel__carousel-left[disabled],
.content-carousel__carousel-right[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
.content-carousel__carousel-left svg,
.content-carousel__carousel-right svg {
  width: 6px;
  height: 10px;
}

.content-carousel__carousel-container {
  display: flex;
  overflow: auto;
  margin-bottom: 20px;
  gap: 10px;
  padding: 9px 24px 20px;
}
@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-container {
    margin-bottom: 10px;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.content-carousel--with-content .content-carousel__carousel-container {
  padding-left: 0;
}
@media screen and (max-width: 62.4375em) {
  .content-carousel--with-content .content-carousel__carousel-container {
    padding-left: 24px;
  }
}
@media screen and (max-width: 42.5em) {
  .content-carousel--with-content .content-carousel__carousel-container {
    padding-left: 16px;
  }
}

@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-item {
    flex: 0 0 auto;
    width: calc(50vw - 24px * 2);
  }
}

.content-carousel__carousel-item-link {
  text-decoration: none;
}
.content-carousel__carousel-item-link:hover {
  text-decoration: none;
}

.content-carousel__carousel-item-image {
  width: 390px;
  margin-bottom: 18px;
}
@media screen and (max-width: 62.4375em) {
  .content-carousel__carousel-item-image {
    width: 280px;
  }
}
@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-item-image {
    width: auto;
  }
}
.content-carousel__carousel-item-image img {
  width: 100%;
  height: auto;
}

.content-carousel__carousel-item-title {
  font-family: "HK Grotesk", Arial, Helvetica, sans-serif;
  line-height: 1.7;
  font-weight: 400;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.3333333333;
  text-decoration: none;
  color: #000000;
}
.content-carousel__carousel-item-link:hover .content-carousel__carousel-item-title {
  text-decoration: underline;
}
@media screen and (max-width: 42.5em) {
  .content-carousel__carousel-item-title {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}